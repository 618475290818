.loading {
  background-color: white;
  height: 100%;
  width: 100%;
}

.yukiIcoLoading {
  content: url(../assets/images/jumperLoading.svg);
  width: 100px;
  animation: rotation 2s infinite linear;
}
