body {
    background-image: url("../assets/images/background.svg") !important;
    background-size:cover;
    background-position:center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height:100%;
}

.badge-notify{
  background:red;
  position:relative;
  top: -20px;
  left: -35px;
}

.drag {
  transition:all 0.4s ease-in-out 0s;
  position: relative;
}

.drag:hover {
  background-color: #0f1316 !important;
  color: white !important;
  transition: 0.3s;
  cursor: pointer;
}

.dragzone {
  border-radius: 2;
  border-width: 2;
  align-items:center;
  border-style: dashed;
  border-color: white;
  transition: 'border .24s ease-in-out';
  background-color:#212529;
}

.imgSize {
  height: 50px;
  width: auto; 
}

.imgSort {
  height: 200px;
  width: auto; 
}

.imgSizeEdit {
  width: auto; 
}

.dropZone:hover {
  background-color: #2125297e;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
    background-color: transparent !important;
}