.intro.plain {
    background: #212529;
    padding: 2rem 0 2rem;
    color: white !important;
}

.introAll.plain {
    padding: 2rem 0 2rem;
    height: auto;
}

.contactText {
    font-size:24px;
}

section {
    background-color: #fff;
}

.right {
    text-align: left;
}

.hug {
    margin-top: 0;
    font-variant-caps: normal;
}

.heading {
    margin-top: 0;
    font-variant-caps: normal;
}

.hugHeading {
    margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

p {
    color:#212529 !important;
}

.yuki {
    width: 85%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 9rem;
}