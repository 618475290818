/* HTML */
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

p {
  font-size: 1rem !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #212529;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #212529;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

a {
  text-decoration: none !important;
}

@font-face {
  font-family: "RalewayL";
  src: url("../assets/font/Raleway-Light.ttf") format("opentype");
}

@font-face {
  font-family: "RalewayE";
  src: url("../assets/font/Raleway-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "RalewayM";
  src: url("../assets/font/Raleway-Medium.ttf") format("opentype");
}

* {
  font-family: "RalewayM", Arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 0.1rem !important;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  background: #f8f9fa !important;
  font-family: "RalewayM", Arial, sans-serif;
  font-size: 17px;
  color: whitesmoke;
  min-height: 100vh
}

.showcase {
  background-image: url("../assets/images/background1.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

@media only screen and (max-width: 990px) {
  .showcase {
    background-attachment: initial !important;
  }
  .showcase3 {
    display: flex;
    background-attachment: initial !important;
    height: 25vh !important;
    align-items: center;
  }
}

.showcase2 {
  background-image: url("../assets/images/background31.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 35vh;
  filter: grayscale(0%);
}

.showcase3 {
  background-image: url("../assets/images/IMG_8802edit.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 17.625rem;
  filter: grayscale(0%);
}

.qr_code {
  width: 100%;
  max-width: 15rem;
}

.atolin {
  width: 100%;
  max-width: 35rem;
}

@media only screen and (min-width: 991px) {
  .navbar-navll {
    font-size: 1.6rem !important;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.scshowcase {
  color: #212529;
}

.gallerySection {
  letter-spacing: 10px;
}

.caption {
  position: absolute;
  left: 0;
  top: 78%;
  width: 100%;
  text-align: center;
  letter-spacing: 25px;
  font-family: "RalewayL" !important;
}

@media only screen and (max-width: 1030px) {
  .caption {
    letter-spacing: 10px !important;
    top: 70% !important;
  }
}

@media only screen and (max-width: 990px) { 
  .captionArrow {
    top: 88% !important;
  }
  .captionProject {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1;
    letter-spacing: 10px;
  }
}

.captionArrow {
  position: absolute;
  left: 0;
  top: 90%;
  width: 100%;
  text-align: center;
}

.captionProject {
  position: absolute;
  left: 0;
  top: 35%;
  width: 100%;
  text-align: center;
  z-index: 1;
  letter-spacing: 10px;
}

.captionContact {
  width: 100%;
  text-align: center;
  z-index: 1;
}

.logo {
  width: 75%;
}

.fab {
  font-size: 25px;
}

.kontakt2 {
  font-size: 24px;
}

.profile {
  color: #212529 !important;
}

.navbar {
  position: fixed;
  top: 0;
  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  transition: 0.75s;
  overflow: visible !important;
}

iframe {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;
}

.borderDown {
  border-bottom: 1px solid black;
  transition: 0.75s;
}

.navbar.colorChange {
  transition: 0.75s;
}

@media only screen and (min-width: 990px) {
  .nav-link {
    font-family: "RalewayM" !important;
    font-size: 1em !important;
    transition: 0.4s !important;
    display: block;
  }
  
  .nav-link:hover {
    font-family: "RalewayM" !important;
    transform: scale(1.1);
    transition: 0.4s;
    color: #222831 !important;
  }
}

@media only screen and (max-width: 990px) {
  .nav-link {
    font-family: "RalewayM" !important;
    font-size: 1rempx;
  }
  .nav-link:hover {
    font-family: "RalewayM" !important;
  }
  .captionArrow {
    top: 93%;
  }
}

@media only screen and (max-width: 990px) {
  .navbar-collapse {
    background-color: #f8f9fa;
  }
  .navbar {
    background-color: #f8f9fa !important;
    border-bottom: 1px solid #000;
  }
}

.footer {
  padding: 3rem 0px;
  height: auto;
  border-top: 1.5px solid black;
}

.tel {
  color: whitesmoke;
}

.cby {
  color: rgb(3, 60, 167);
  font-size: 14px;
}

.borderNav {
  border-bottom: 1px solid black;
}

.footerLogo {
  width: 110px;
  height: 40px;
}

.footersvg {
  width: 100px;
}

.navbarsvg {
  width: 130px;
  transition: 0.5s;
}

@media only screen and (max-width: 990px) {
  .navbarsvg {
    width: 80px;
    transition: 0.3s;
  }
  .navbarSvgSmaller {
    width: 80px !important;
    transition: 0.3s;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: black !important;
  background-color: transparent !important;
  transition: 0.75s;
}

.navbar-fixed-top {
  width: 100vw !important;
  display: block !important;
}

.navbarSvgSmaller {
  width: 85px;
  transition: 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 15px !important;
}

.wrapper a:nth-child(1) {
  color: #3b5998;
}

.wrapper .icon {
  padding: 10px;
  text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.6);
  transition: all ease-in-out 200ms;
}

.wrapper .icon:hover {
  transform: scale(1.1);
  transition: all ease-in-out 200ms;
  text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.6);
}